import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";

import {
    FaWhatsapp,
    FaFacebookF,
    FaTelegramPlane,
    FaTwitter,
    FaEnvelope,
    FaPrint,
} from "react-icons/fa";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost;

    const url = "https://www.tombatruites.cat/" + post.slug;

    const shareText = "Mira aquesta recepta: " + post.title + " - " + url;

    const telegramUrl =
        "https://t.me/share/url?url=" +
        encodeURIComponent(url) +
        "&text=" +
        encodeURIComponent(post.title);

    const twitterUrl =
        "https://twitter.com/share?url=" +
        encodeURIComponent(url) +
        "&text=" +
        encodeURIComponent(post.title);

    const mailUrl =
        "mailto:?subject=" +
        encodeURIComponent(post.title) +
        "&body=" +
        encodeURIComponent(url);

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container-post">
                    <article className="content">
                        <figure
                            className="post-feature-image"
                            style={{
                                backgroundImage: `url(${post.feature_image})`,
                            }}
                        >
                            <h1 className="content-title">
                                <span>{post.title}</span>
                            </h1>
                        </figure>
                    </article>
                    <article className="content">
                        <section className="post-full-content">
                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts post-content"
                                dangerouslySetInnerHTML={{
                                    __html: post.html,
                                }}
                            />
                            <hr />
                            <div className="site-mast">
                                <h2 className="tagsPost">ETIQUETES:</h2>
                            </div>

                            <div
                                className="tag-list-post-detail"
                                style={{
                                    paddingBottom: 20 + "px",
                                }}
                            >
                                {post.tags.map((tag) => {
                                    return (
                                        <Link
                                            key={tag.slug}
                                            className="tag-list-post-detail-item"
                                            to={`/tag/` + tag.slug}
                                        >
                                            {`# ` + tag.name}
                                        </Link>
                                    );
                                })}
                            </div>
                            <hr />

                            <div className="site-mast">
                                <h2 className="tagsPost">
                                    COMPARTEIX AQUESTA RECEPTA:
                                </h2>
                            </div>
                            <div
                                className="site-mast"
                                style={{
                                    paddingBottom: 60 + "px",
                                }}
                            >
                                <div className="share-menu">
                                    <a
                                        href={
                                            "whatsapp://send?text=" + shareText
                                        }
                                        data-action="share/whatsapp/share"
                                    >
                                        <FaWhatsapp
                                            className="share-menu-item"
                                            alt="Comparteix per Whatsapp"
                                        />
                                    </a>

                                    <a
                                        href={telegramUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaTelegramPlane
                                            className="share-menu-item"
                                            alt="Comparteix per Telegram"
                                        />
                                    </a>

                                    <a
                                        href={
                                            "https://www.facebook.com/sharer/sharer.php?u=" +
                                            encodeURIComponent(url)
                                        }
                                        title="Comparetix a Facebook"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaFacebookF
                                            className="share-menu-item"
                                            alt="Comparteix per Facebook"
                                        />
                                    </a>
                                    <a
                                        href={twitterUrl}
                                        title="Comparetix a Twitter"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaTwitter
                                            className="share-menu-item"
                                            alt="Comparteix per Twitter"
                                        />
                                    </a>

                                    <a
                                        href={mailUrl}
                                        title="Comparteix per email"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaEnvelope
                                            className="share-menu-item"
                                            alt="Comparteix per email"
                                        />
                                    </a>
                                    <a
                                        href="#"
                                        onClick={() => {
                                            window.print();
                                        }}
                                    >
                                        <FaPrint
                                            className="share-menu-item"
                                            alt="Imprimeix la recepta"
                                        />
                                    </a>
                                </div>
                            </div>
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            tags: PropTypes.array.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`;
